// a writable store backed by localstorage
import { writable } from 'svelte/store'

export const createWritableStore = (key, startValue) => {
  const { subscribe, set } = writable(startValue)
  return {
    subscribe,
    set,
    useLocalStorage: () => {
      const json = localStorage.getItem(key)
      if (json) {
        //console.log('useLocalStorage '+ key + ' ' + json)
        let parsed_json = ""
        try {
          parsed_json = JSON.parse(json)
        } catch (e) {
          console.log('error parsing json')
          //console.log(e)
        }
        //set(JSON.parse(json));
        if (parsed_json) {
          set(parsed_json)
        }
      }
      subscribe(val => {
        // console.log('useLocalStorage - subscribe ' + val)
        localStorage.setItem(key, JSON.stringify(val))
      })
    }
  }
}

export const zaktorMatata = createWritableStore('matata', {})
export const settings = createWritableStore('settings', {})
export const cart = createWritableStore('cart', {})

export function createCart(shop) {
  return createWritableStore('cart-' + shop.id, {})
}
