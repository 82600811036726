// import { addMessages } from 'svelte-i18n'
//
// import sl from '../locale/sl.json'
// import en from '../locale/en.json'
//
// addMessages('sl', sl)
// addMessages('en', en)
// addMessages('en-US', enUS)

//register('en', () => import('../locale/en.json'))
//register('sl', () => import('../locale/sl.json'))
//init({
//  fallbackLocale: 'sl',
//  initialLocale: getLocaleFromNavigator()
//})
//locale.set('sl')

import { addMessages, locale } from 'svelte-i18n'

import sl from '../locale/sl.json'
import en from '../locale/en.json'

export const initLocale = () => {
  addMessages('sl-SI', sl)
  addMessages('en-US', en)

  locale.set('en-US')
}
