export function focus(element) {
  element.focus()
}

export function formatCurrency(value, locale, currency, maximumFractionDigits, minimumFractionDigits) {
  // console.log('formatCurrency value ' + value)
  // console.log('formatCurrency locale ' + locale)
  // console.log('formatCurrency currency ' + currency)
  return new Intl.NumberFormat(locale, {
    currency: currency,
    style: 'currency',
    maximumFractionDigits: maximumFractionDigits || 0,
    minimumFractionDigits: minimumFractionDigits || 0
  }).format(value)
}

export function filteredCryptos(toKeepCryptos) {
  let items = cryptos
  toKeepCryptos.forEach(function(c) {
    items = items.filter((item) => item.id === c.id)
  })

  return items
}

export const cryptos = [
  {"title": "Al-Buraq", id: "Al-Buraq", img: "Al-Buraq_Logo.png"},
  {"title": "Alias", id: "Alias", img: "Alias_Logo.png"},
  {"title": "Ark", id: "Ark", img: "Ark_Logo.png"},
  {"title": "Audax", id: "Audax", img: "Audax_Logo.png"},
  {"title": "Audiocoin", id: "Audiocoin", img: "Audiocoin_Logo.png"},
  {"title": "Axe", id: "Axe", img: "Axe_Logo.png"},
  {"title": "Banano", id: "Banano", img: "Banano_Logo.png"},
  {"title": "Beldex", id: "Beldex", img: "Beldex_Logo.png"},
  {"title": "Binjit", id: "Binjit", img: "Binjit_Logo.png"},
  {"title": "Birake", id: "Birake", img: "Birake_Logo.png"},
  {"title": "BitGreen", id: "BitGreen", img: "BitGreen_Logo.png"},
  {"title": "Bitcash", id: "Bitcash", img: "Bitcash_Logo.png"},
  {"title": "BitcoinSubsidium", id: "BitcoinSubsidium", img: "Bitcoin-Subsidium_Logo.png"},
  {"title": "BitcoinZ", id: "BitcoinZ", img: "BitcoinZ_Logo.png"},
  {"title": "Bitcoin", id: "Bitcoin", img: "Bitcoin_Logo.png"},
  {"title": "Bitcoincash", id: "Bitcoincash", img: "Bitcoincash_Logo.png"},
  {"title": "Blocx", id: "Blocx", img: "Blocx_Logo.png"},
  {"title": "ColossusXT", id: "ColossusXT", img: "ColossusXT_Logo.png"},
  {"title": "Conceal", id: "Conceal", img: "Conceal_Logo.png"},
  {"title": "CryptoDevelopmentServices", id: "CryptoDevelopmentServices", img: "CryptoDevelopmentServices_Logo.png"},
  {"title": "CryptoDezireCash", id: "CryptoDezireCash", img: "CryptoDezireCash_Logo.png"},
  {"title": "Cryptocurrency", id: "Cryptocurrency", img: "Cryptocurrency_Logo.png"},
  {"title": "Daps", id: "Daps", img: "Daps_Logo.png"},
  {"title": "Dash", id: "Dash", img: "Dash_Logo.png"},
  {"title": "DigitalNote", id: "DigitalNote", img: "DigitalNote_Logo.png"},
  {"title": "Documentchain", id: "Documentchain", img: "Documentchain_Logo.png"},
  {"title": "Doge", id: "Doge", img: "Doge_Logo.png"},
  {"title": "DragonChain", id: "DragonChain", img: "DragonChain_Logo.png"},
  {"title": "Electra", id: "Electra", img: "Electra_Logo.png"},
  {"title": "Ergo", id: "Ergo", img: "Ergo_Logo.png"},
  {"title": "Ethereum", id: "Ethereum", img: "Ethereum_Logo.png"},
  {"title": "Etho", id: "Etho", img: "Etho_Logo.png"},
  {"title": "Flux", id: "Flux", img: "Flux_Logo.png"},
  {"title": "FrenchDigitalReserve", id: "FrenchDigitalReserve", img: "FrenchDigitalReserve_Logo.png"},
  {"title": "Galilel", id: "Galilel", img: "Galilel_Logo.png"},
  {"title": "Garlicoin", id: "Garlicoin", img: "Garlicoin_Logo.png"},
  {"title": "Gather", id: "Gather", img: "Gather_Logo.png"},
  {"title": "Ghost", id: "Ghost", img: "Ghost_Logo.png"},
  {"title": "GoldenRatioToken", id: "GoldenRatioToken", img: "GoldenRatioToken_Logo.png"},
  {"title": "Haven", id: "Haven", img: "Haven_Logo.png"},
  {"title": "HempCoin", id: "HempCoin", img: "HempCoin_Logo.png"},
  {"title": "Hush", id: "Hush", img: "Hush_Logo.png"},
  {"title": "Idx", id: "Idx", img: "Idx_Logo.png"},
  {"title": "Kaspa", id: "Kaspa", img: "Kaspa_Logo.png"},
  {"title": "Kiirocoin", id: "Kiirocoin", img: "Kiirocoin_Logo.png"},
  {"title": "Klimatas", id: "Klimatas", img: "Klimatas_Logo.png"},
  {"title": "Komodo", id: "Komodo", img: "Komodo_Logo.png"},
  {"title": "Litecash", id: "Litecash", img: "Litecash_Logo.png"},
  {"title": "Litecoin", id: "Litecoin", img: "Litecoin_Logo.png"},
  {"title": "Maria", id: "Maria", img: "Maria_Logo.png"},
  {"title": "Monero", id: "Monero", img: "Monero_Logo.png"},
  {"title": "Moonshot", id: "Moonshot", img: "Moonshot_Logo.png"},
  {"title": "Mota", id: "Mota", img: "Mota_Logo.png"},
  {"title": "Nerva", id: "Nerva", img: "Nerva_Logo.png"},
  {"title": "NestEGG", id: "NestEGG", img: "NestEGG_Logo.png"},
  {"title": "Netbox", id: "Netbox", img: "Netbox_Logo.png"},
  {"title": "Nexa", id: "Nexa", img: "Nexa_Logo.png"},
  {"title": "NightLifeCrypto", id: "NightLifeCrypto", img: "NightLifeCrypto_Logo.png"},
  {"title": "Niobio", id: "Niobio", img: "Niobio_Logo.png"},
  {"title": "OKcash", id: "OKcash", img: "OKcash_Logo.png"},
  {"title": "Paprikacoin", id: "Paprikacoin", img: "Paprikacoin_Logo.png"},
  {"title": "Pengolin", id: "Pengolin", img: "Pengolin_Logo.png"},
  {"title": "PirateChain", id: "PirateChain", img: "PirateChain_Logo.png"},
  {"title": "Pivx", id: "Pivx", img: "Pivx_Logo.png"},
  {"title": "Primo", id: "Primo", img: "Primo_Logo.png"},
  {"title": "Privacy", id: "Privacy", img: "Privacy_Logo.png"},
  {"title": "Rapids", id: "Rapids", img: "Rapids_Logo.png"},
  {"title": "Raptoreum", id: "Raptoreum", img: "Raptoreum_Logo.png"},
  {"title": "Ripple", id: "Ripple", img: "Ripple_Logo.png"},
  {"title": "Rito", id: "Rito", img: "Rito_Logo.png"},
  {"title": "Send", id: "Send", img: "Send_Logo.png"},
  {"title": "Sentinel", id: "Sentinel", img: "Sentinel_Logo.png"},
  {"title": "SigmaUSD", id: "SigmaUSD", img: "SigmaUSD_Logo.png"},
  {"title": "Signum", id: "Signum", img: "Signum_Logo.png"},
  {"title": "Sinovate", id: "Sinovate", img: "Sinovate_Logo.png"},
  {"title": "Sumokoin", id: "Sumokoin", img: "Sumokoin_Logo.png"},
  {"title": "Tether", id: "Tether", img: "Tether_Logo.png"},
  {"title": "Transcendence", id: "Transcendence", img: "Transcendence_Logo.png"},
  {"title": "Uplexa", id: "Uplexa", img: "Uplexa_Logo.png"},
  {"title": "Veil", id: "Veil", img: "Veil_Logo.png"},
  {"title": "Verus", id: "Verus", img: "Verus_Logo.png"},
  {"title": "Xelis", id: "Xelis", img: "Xelis_Logo.png"},
  {"title": "Zenzo", id: "Zenzo", img: "Zenzo_Logo.png"},
  {"title": "Zero", id: "Zero", img: "Zero_Logo.png"}
]

export const countries = [
    { "text": "Afghanistan", "value": "AF" },
    { "text": "Åland Islands", "value": "AX" },
    { "text": "Albania", "value": "AL" },
    { "text": "Algeria", "value": "DZ" },
    { "text": "American Samoa", "value": "AS" },
    { "text": "Andorra", "value": "AD" },
    { "text": "Angola", "value": "AO" },
    { "text": "Anguilla", "value": "AI" },
    { "text": "Antarctica", "value": "AQ" },
    { "text": "Antigua and Barbuda", "value": "AG" },
    { "text": "Argentina", "value": "AR" },
    { "text": "Armenia", "value": "AM" },
    { "text": "Aruba", "value": "AW" },
    { "text": "Australia", "value": "AU" },
    { "text": "Austria", "value": "AT" },
    { "text": "Azerbaijan", "value": "AZ" },
    { "text": "Bahamas", "value": "BS" },
    { "text": "Bahrain", "value": "BH" },
    { "text": "Bangladesh", "value": "BD" },
    { "text": "Barbados", "value": "BB" },
    { "text": "Belarus", "value": "BY" },
    { "text": "Belgium", "value": "BE" },
    { "text": "Belize", "value": "BZ" },
    { "text": "Benin", "value": "BJ" },
    { "text": "Bermuda", "value": "BM" },
    { "text": "Bhutan", "value": "BT" },
    { "text": "Bolivia", "value": "BO" },
    { "text": "Bosnia and Herzegovina", "value": "BA" },
    { "text": "Botswana", "value": "BW" },
    { "text": "Bouvet Island", "value": "BV" },
    { "text": "Brazil", "value": "BR" },
    { "text": "British Indian Ocean Territory", "value": "IO" },
    { "text": "Brunei Darussalam", "value": "BN" },
    { "text": "Bulgaria", "value": "BG" },
    { "text": "Burkina Faso", "value": "BF" },
    { "text": "Burundi", "value": "BI" },
    { "text": "Cambodia", "value": "KH" },
    { "text": "Cameroon", "value": "CM" },
    { "text": "Canada", "value": "CA" },
    { "text": "Cape Verde", "value": "CV" },
    { "text": "Cayman Islands", "value": "KY" },
    { "text": "Central African Republic", "value": "CF" },
    { "text": "Chad", "value": "TD" },
    { "text": "Chile", "value": "CL" },
    { "text": "China", "value": "CN" },
    { "text": "Christmas Island", "value": "CX" },
    { "text": "Cocos (Keeling) Islands", "value": "CC" },
    { "text": "Colombia", "value": "CO" },
    { "text": "Comoros", "value": "KM" },
    { "text": "Congo", "value": "CG" },
    { "text": "Congo, The Democratic Republic of the", "value": "CD" },
    { "text": "Cook Islands", "value": "CK" },
    { "text": "Costa Rica", "value": "CR" },
    { "text": "Cote D'Ivoire", "value": "CI" },
    { "text": "Croatia", "value": "HR" },
    { "text": "Cuba", "value": "CU" },
    { "text": "Cyprus", "value": "CY" },
    { "text": "Czech Republic", "value": "CZ" },
    { "text": "Denmark", "value": "DK" },
    { "text": "Djibouti", "value": "DJ" },
    { "text": "Dominica", "value": "DM" },
    { "text": "Dominican Republic", "value": "DO" },
    { "text": "Ecuador", "value": "EC" },
    { "text": "Egypt", "value": "EG" },
    { "text": "El Salvador", "value": "SV" },
    { "text": "Equatorial Guinea", "value": "GQ" },
    { "text": "Eritrea", "value": "ER" },
    { "text": "Estonia", "value": "EE" },
    { "text": "Ethiopia", "value": "ET" },
    { "text": "Falkland Islands (Malvinas)", "value": "FK" },
    { "text": "Faroe Islands", "value": "FO" },
    { "text": "Fiji", "value": "FJ" },
    { "text": "Finland", "value": "FI" },
    { "text": "France", "value": "FR" },
    { "text": "French Guiana", "value": "GF" },
    { "text": "French Polynesia", "value": "PF" },
    { "text": "French Southern Territories", "value": "TF" },
    { "text": "Gabon", "value": "GA" },
    { "text": "Gambia", "value": "GM" },
    { "text": "Georgia", "value": "GE" },
    { "text": "Germany", "value": "DE" },
    { "text": "Ghana", "value": "GH" },
    { "text": "Gibraltar", "value": "GI" },
    { "text": "Greece", "value": "GR" },
    { "text": "Greenland", "value": "GL" },
    { "text": "Grenada", "value": "GD" },
    { "text": "Guadeloupe", "value": "GP" },
    { "text": "Guam", "value": "GU" },
    { "text": "Guatemala", "value": "GT" },
    { "text": "Guernsey", "value": "GG" },
    { "text": "Guinea", "value": "GN" },
    { "text": "Guinea-Bissau", "value": "GW" },
    { "text": "Guyana", "value": "GY" },
    { "text": "Haiti", "value": "HT" },
    { "text": "Heard Island and Mcdonald Islands", "value": "HM" },
    { "text": "Holy See (Vatican City State)", "value": "VA" },
    { "text": "Honduras", "value": "HN" },
    { "text": "Hong Kong", "value": "HK" },
    { "text": "Hungary", "value": "HU" },
    { "text": "Iceland", "value": "IS" },
    { "text": "India", "value": "IN" },
    { "text": "Indonesia", "value": "ID" },
    { "text": "Iran, Islamic Republic Of", "value": "IR" },
    { "text": "Iraq", "value": "IQ" },
    { "text": "Ireland", "value": "IE" },
    { "text": "Isle of Man", "value": "IM" },
    { "text": "Israel", "value": "IL" },
    { "text": "Italy", "value": "IT" },
    { "text": "Jamaica", "value": "JM" },
    { "text": "Japan", "value": "JP" },
    { "text": "Jersey", "value": "JE" },
    { "text": "Jordan", "value": "JO" },
    { "text": "Kazakhstan", "value": "KZ" },
    { "text": "Kenya", "value": "KE" },
    { "text": "Kiribati", "value": "KI" },
    { "text": "Korea, Democratic People'S Republic of", "value": "KP" },
    { "text": "Korea, Republic of", "value": "KR" },
    { "text": "Kuwait", "value": "KW" },
    { "text": "Kyrgyzstan", "value": "KG" },
    { "text": "Lao People'S Democratic Republic", "value": "LA" },
    { "text": "Latvia", "value": "LV" },
    { "text": "Lebanon", "value": "LB" },
    { "text": "Lesotho", "value": "LS" },
    { "text": "Liberia", "value": "LR" },
    { "text": "Libyan Arab Jamahiriya", "value": "LY" },
    { "text": "Liechtenstein", "value": "LI" },
    { "text": "Lithuania", "value": "LT" },
    { "text": "Luxembourg", "value": "LU" },
    { "text": "Macao", "value": "MO" },
    { "text": "Macedonia, The Former Yugoslav Republic of", "value": "MK" },
    { "text": "Madagascar", "value": "MG" },
    { "text": "Malawi", "value": "MW" },
    { "text": "Malaysia", "value": "MY" },
    { "text": "Maldives", "value": "MV" },
    { "text": "Mali", "value": "ML" },
    { "text": "Malta", "value": "MT" },
    { "text": "Marshall Islands", "value": "MH" },
    { "text": "Martinique", "value": "MQ" },
    { "text": "Mauritania", "value": "MR" },
    { "text": "Mauritius", "value": "MU" },
    { "text": "Mayotte", "value": "YT" },
    { "text": "Mexico", "value": "MX" },
    { "text": "Micronesia, Federated States of", "value": "FM" },
    { "text": "Moldova, Republic of", "value": "MD" },
    { "text": "Monaco", "value": "MC" },
    { "text": "Mongolia", "value": "MN" },
    { "text": "Montserrat", "value": "MS" },
    { "text": "Morocco", "value": "MA" },
    { "text": "Mozambique", "value": "MZ" },
    { "text": "Myanmar", "value": "MM" },
    { "text": "Namibia", "value": "NA" },
    { "text": "Nauru", "value": "NR" },
    { "text": "Nepal", "value": "NP" },
    { "text": "Netherlands", "value": "NL" },
    { "text": "Netherlands Antilles", "value": "AN" },
    { "text": "New Caledonia", "value": "NC" },
    { "text": "New Zealand", "value": "NZ" },
    { "text": "Nicaragua", "value": "NI" },
    { "text": "Niger", "value": "NE" },
    { "text": "Nigeria", "value": "NG" },
    { "text": "Niue", "value": "NU" },
    { "text": "Norfolk Island", "value": "NF" },
    { "text": "Northern Mariana Islands", "value": "MP" },
    { "text": "Norway", "value": "NO" },
    { "text": "Oman", "value": "OM" },
    { "text": "Pakistan", "value": "PK" },
    { "text": "Palau", "value": "PW" },
    { "text": "Palestinian Territory, Occupied", "value": "PS" },
    { "text": "Panama", "value": "PA" },
    { "text": "Papua New Guinea", "value": "PG" },
    { "text": "Paraguay", "value": "PY" },
    { "text": "Peru", "value": "PE" },
    { "text": "Philippines", "value": "PH" },
    { "text": "Pitcairn", "value": "PN" },
    { "text": "Poland", "value": "PL" },
    { "text": "Portugal", "value": "PT" },
    { "text": "Puerto Rico", "value": "PR" },
    { "text": "Qatar", "value": "QA" },
    { "text": "Reunion", "value": "RE" },
    { "text": "Romania", "value": "RO" },
    { "text": "Russian Federation", "value": "RU" },
    { "text": "RWANDA", "value": "RW" },
    { "text": "Saint Helena", "value": "SH" },
    { "text": "Saint Kitts and Nevis", "value": "KN" },
    { "text": "Saint Lucia", "value": "LC" },
    { "text": "Saint Pierre and Miquelon", "value": "PM" },
    { "text": "Saint Vincent and the Grenadines", "value": "VC" },
    { "text": "Samoa", "value": "WS" },
    { "text": "San Marino", "value": "SM" },
    { "text": "Sao Tome and Principe", "value": "ST" },
    { "text": "Saudi Arabia", "value": "SA" },
    { "text": "Senegal", "value": "SN" },
    { "text": "Serbia and Montenegro", "value": "CS" },
    { "text": "Seychelles", "value": "SC" },
    { "text": "Sierra Leone", "value": "SL" },
    { "text": "Singapore", "value": "SG" },
    { "text": "Slovakia", "value": "SK" },
    { "text": "Slovenia", "value": "SI" },
    { "text": "Solomon Islands", "value": "SB" },
    { "text": "Somalia", "value": "SO" },
    { "text": "South Africa", "value": "ZA" },
    { "text": "South Georgia and the South Sandwich Islands", "value": "GS" },
    { "text": "Spain", "value": "ES" },
    { "text": "Sri Lanka", "value": "LK" },
    { "text": "Sudan", "value": "SD" },
    { "text": "Suriname", "value": "SR" },
    { "text": "Svalbard and Jan Mayen", "value": "SJ" },
    { "text": "Swaziland", "value": "SZ" },
    { "text": "Sweden", "value": "SE" },
    { "text": "Switzerland", "value": "CH" },
    { "text": "Syrian Arab Republic", "value": "SY" },
    { "text": "Taiwan, Province of China", "value": "TW" },
    { "text": "Tajikistan", "value": "TJ" },
    { "text": "Tanzania, United Republic of", "value": "TZ" },
    { "text": "Thailand", "value": "TH" },
    { "text": "Timor-Leste", "value": "TL" },
    { "text": "Togo", "value": "TG" },
    { "text": "Tokelau", "value": "TK" },
    { "text": "Tonga", "value": "TO" },
    { "text": "Trinidad and Tobago", "value": "TT" },
    { "text": "Tunisia", "value": "TN" },
    { "text": "Turkey", "value": "TR" },
    { "text": "Turkmenistan", "value": "TM" },
    { "text": "Turks and Caicos Islands", "value": "TC" },
    { "text": "Tuvalu", "value": "TV" },
    { "text": "Uganda", "value": "UG" },
    { "text": "Ukraine", "value": "UA" },
    { "text": "United Arab Emirates", "value": "AE" },
    { "text": "United Kingdom", "value": "GB" },
    { "text": "United States", "value": "US" },
    { "text": "United States Minor Outlying Islands", "value": "UM" },
    { "text": "Uruguay", "value": "UY" },
    { "text": "Uzbekistan", "value": "UZ" },
    { "text": "Vanuatu", "value": "VU" },
    { "text": "Venezuela", "value": "VE" },
    { "text": "Viet Nam", "value": "VN" },
    { "text": "Virgin Islands, British", "value": "VG" },
    { "text": "Virgin Islands, U.S.", "value": "VI" },
    { "text": "Wallis and Futuna", "value": "WF" },
    { "text": "Western Sahara", "value": "EH" },
    { "text": "Yemen", "value": "YE" },
    { "text": "Zambia", "value": "ZM" },
    { "text": "Zimbabwe", "value": "ZW" }
  ]