const getHeaders = () => {
  let headers = new window.Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  })
  let csrfToken = document.head.querySelector("[name='csrf-token']")
  if (csrfToken && csrfToken['content']) { headers.append('X-CSRF-Token', csrfToken['content']) }

  return headers
}
const getUploadHeaders = () => {
  let headers = new window.Headers({
    // 'Content-Type': 'multipart/form-data', Explicitly don't set this! https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
    'Accept': 'application/json' //,
    //'X-Requested-With': 'XMLHttpRequest'
  })
  let csrfToken = document.head.querySelector("[name='csrf-token']")
  if (csrfToken && csrfToken['content']) { headers.append('X-CSRF-Token', csrfToken['content']) }

  return headers
}

export function createRequest(url, method = 'get', body = '') {
  const request = new window.Request(url, {
    headers: getHeaders(),
    method: method,
    credentials: 'same-origin',
    dataType: 'json',
    body: body
  })
  return request
}

export function createPostRequest(url, method = 'post', body = '') {
  const request = new window.Request(url, {
    headers: getHeaders(),
    method: method,
    credentials: 'same-origin',
    dataType: 'json',
    body: body
  })
  return request
}

export function createUploadRequest(url, method = 'post',body = '') {
  const request = new window.Request(url, {
    headers: getUploadHeaders(),
    method: method,
    credentials: 'same-origin',
    dataType: 'json',
    body: body
  })
  return request
}

export function createGetRequest(url, method = 'get') {
  const request = new window.Request(url, {
    headers: getHeaders(),
    method: method,
    credentials: 'same-origin',
    dataType: 'json'
  })
  return request
}

export function createDeleteRequest(url, method = 'delete') {
  const request = new window.Request(url, {
    headers: getHeaders(),
    method: method,
    credentials: 'same-origin',
    dataType: 'json'
  })
  return request
}